import { FC } from 'react'
import styled from 'styled-components'
import { Button } from '@netpurpose/np-ui'
import { StyledDivider } from '@netpurpose/np-ui/src/components/PageSection/PageSection.style'
import ComparePortfoliosAction from '#components/portfolio/ComparePortfoliosAction'
import { CreatePortfolioAction } from '#components/portfolio/CreatePortfolioAction'
import { DeletePortfolioAction } from '#components/portfolio/DeletePortfolioAction'
import { MultiplePortfolioActionProps } from '#components/portfolio/portfolioTypes'

const ActionButton = styled(Button)`
  height: 2rem;
  width: 16rem;
  line-height: 1rem;
`

const CreateButton = ({ onClick }: { onClick: () => void }) => (
  <ActionButton onClick={onClick} size="small">
    Create Portfolio
  </ActionButton>
)

const Actions: FC<MultiplePortfolioActionProps> = ({
  selectedPortfolioIds,
  setSelectedPortfolioIds,
}) => (
  <>
    <ComparePortfoliosAction
      selectedPortfolioIds={selectedPortfolioIds}
      setSelectedPortfolioIds={setSelectedPortfolioIds}
    />
    <StyledDivider type="vertical" />
    <DeletePortfolioAction selectedPortfolioIds={selectedPortfolioIds} />
    <StyledDivider type="vertical" />
    <CreatePortfolioAction renderAction={CreateButton} />
  </>
)

export default Actions
