// This file should be temporary. Ideally we should just use the BE enum types in the FE.
import { DatapointStatus, DatapointType } from '@netpurpose/types'
import {
  DatapointStatus as BackendDatapointStatus,
  DatapointTypes,
} from '../../../generated/modelling'

export const mapDatapointTypeBEToFE = (type: DatapointTypes | undefined): DatapointType => {
  switch (type) {
    case DatapointTypes.IMPACT:
      return DatapointType.Impact
    case DatapointTypes.MACRO_FACTOR:
      return DatapointType.MacroFactor
    case DatapointTypes.OUTCOME_LEVEL_1:
      return DatapointType.OutcomeLevel1
    case DatapointTypes.OUTCOME_LEVEL_2:
      return DatapointType.OutcomeLevel2
    case DatapointTypes.OUTPUT:
      return DatapointType.Output
    case DatapointTypes.REVENUE:
      return DatapointType.Revenue
    case DatapointTypes.TECHNOLOGY_COST:
      return DatapointType.TechnologyCost
    default:
      return DatapointType.Datapoint
  }
}

export const mapDatapointStatusBEToFE = (
  status: BackendDatapointStatus | undefined,
): DatapointStatus =>
  status === BackendDatapointStatus.ACCEPTED ? DatapointStatus.Accepted : DatapointStatus.Created
