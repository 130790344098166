import { AxiosHeaders, InternalAxiosRequestConfig } from 'axios'
import { getToken } from '@netpurpose/api'

export const attachAuthToken = async (
  conf: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
  const token = await getToken()
  if (!token) {
    return conf
  }
  return {
    ...conf,
    headers: new AxiosHeaders({
      ...conf.headers,
      Authorization: `Bearer ${token}`,
    }),
  }
}
