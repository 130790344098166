import { Analytics, getAnalytics, isSupported, logEvent, setUserId } from 'firebase/analytics'
import { useRouter } from 'next/router'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { firebaseApp } from '@netpurpose/api'
import { useLoggedIn } from '@netpurpose/core'
import { logger } from '@netpurpose/utils'
import { config } from '#services/config'
import { AnalyticsEventName, AnalyticsEventParams } from '#types/analytics'

const analyticsEnabled =
  config.firebase.analyticsEnabled && config.firebase.measurementId && config.firebase.appId

// Privacy-first browsers such as Firefox will block analytics in such a way
// that we need to track whether tracking is supported at all and avoid throwing
// an error below if not, otherwise the whole application won't load.
let trackingSupported: null | boolean = null

export const defaultContext = null

export const AnalyticsContext = React.createContext<Analytics | null>(defaultContext)

export const AnalyticsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter()
  const { user } = useLoggedIn()
  const [tracking, setTracking] = useState<Analytics | null>(defaultContext)

  const userId = user?.id

  useEffect(() => {
    // Disable analytics for local development.
    if (analyticsEnabled) {
      isSupported().then((isSupp) => {
        if (!isSupp) {
          logger.warn({ err: 'Analytics not supported' })
          trackingSupported = false
          return
        }

        const analytics = getAnalytics(firebaseApp)
        setTracking(analytics)

        trackingSupported = true

        if (userId) {
          setUserId(analytics, userId)
        }
      })

      const handleRouteChange = (url: string) => {
        if (!tracking) {
          return
        }

        logEvent(tracking, AnalyticsEventName.PageView, {
          page_location: url,
          page_title: document?.title,
        })
      }

      router.events.on('routeChangeStart', handleRouteChange)

      return () => {
        router.events.off('routeChangeStart', handleRouteChange)
      }
    }
    return
  }, [router.events, tracking, userId])

  return <AnalyticsContext.Provider value={tracking}>{children}</AnalyticsContext.Provider>
}

export const useAnalytics = () => {
  const context = React.useContext(AnalyticsContext)
  if (analyticsEnabled && trackingSupported && context === null) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider')
  }
  return {
    logEvent: (eventName: AnalyticsEventName | string, eventParams: AnalyticsEventParams) => {
      if (context) {
        logEvent(context, eventName, eventParams)
      }
    },
    app: context,
  }
}

export type AnalyticsContextType = ReturnType<typeof useAnalytics>
