import styled from 'styled-components'
import { PortfolioType } from '@netpurpose/api'

export const Badge = styled.div<{ type: PortfolioType | 'company' }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: 0.625rem;
  font-weight: 700;
  font-size: 0.6875rem;
  width: 1.25rem;
  height: 1.25rem;

  background-color: ${({ theme, type }) => {
    if (type === 'benchmark') {
      return theme.colors.architecture5
    }
    if (type === 'fund') {
      return theme.colors.typeSecondary
    }
    return theme.colors.black
  }};
  color: ${({ theme }) => theme.colors.typeDarkPrimary};
`

export const OverlappingBadges = styled.div`
  display: flex;
  > div {
    outline: 1px solid white;
  }

  :not(:first-child) {
    position: relative;
    margin-left: -5px;
  }
`
