import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  white-space: nowrap;

  > div {
    margin-left: 0.5rem;
  }
`
