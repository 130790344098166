import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { Interceptors } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AcceptedFactsService } from './sdk.gen';
import { BatchesService } from './sdk.gen';
import { CoverageService } from './sdk.gen';
import { DataDictionaryService } from './sdk.gen';
import { DefaultService } from './sdk.gen';
import { EntitiesService } from './sdk.gen';
import { EnumsService } from './sdk.gen';
import { ErrorsService } from './sdk.gen';
import { FactsService } from './sdk.gen';
import { FeedbackService } from './sdk.gen';
import { HoldingsService } from './sdk.gen';
import { IsinMappingsService } from './sdk.gen';
import { KeywordsService } from './sdk.gen';
import { KpiResultsService } from './sdk.gen';
import { KpisService } from './sdk.gen';
import { LabelsService } from './sdk.gen';
import { MetricsService } from './sdk.gen';
import { PortfoliosService } from './sdk.gen';
import { QuestionsService } from './sdk.gen';
import { ReportingStandardsService } from './sdk.gen';
import { ResultsService } from './sdk.gen';
import { SearchService } from './sdk.gen';
import { SegmentsService } from './sdk.gen';
import { SemanticSearchService } from './sdk.gen';
import { SnapshotsService } from './sdk.gen';
import { SourcesService } from './sdk.gen';
import { StatsService } from './sdk.gen';
import { TasksService } from './sdk.gen';
import { UnitsService } from './sdk.gen';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {

	public readonly acceptedFacts: AcceptedFactsService;
	public readonly batches: BatchesService;
	public readonly coverage: CoverageService;
	public readonly dataDictionary: DataDictionaryService;
	public readonly default: DefaultService;
	public readonly entities: EntitiesService;
	public readonly enums: EnumsService;
	public readonly errors: ErrorsService;
	public readonly facts: FactsService;
	public readonly feedback: FeedbackService;
	public readonly holdings: HoldingsService;
	public readonly isinMappings: IsinMappingsService;
	public readonly keywords: KeywordsService;
	public readonly kpiResults: KpiResultsService;
	public readonly kpis: KpisService;
	public readonly labels: LabelsService;
	public readonly metrics: MetricsService;
	public readonly portfolios: PortfoliosService;
	public readonly questions: QuestionsService;
	public readonly reportingStandards: ReportingStandardsService;
	public readonly results: ResultsService;
	public readonly search: SearchService;
	public readonly segments: SegmentsService;
	public readonly semanticSearch: SemanticSearchService;
	public readonly snapshots: SnapshotsService;
	public readonly sources: SourcesService;
	public readonly stats: StatsService;
	public readonly tasks: TasksService;
	public readonly units: UnitsService;

	public readonly request: BaseHttpRequest;

	constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
		this.request = new HttpRequest({
			BASE: config?.BASE ?? '',
			VERSION: config?.VERSION ?? '1.0.0',
			WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
			CREDENTIALS: config?.CREDENTIALS ?? 'include',
			TOKEN: config?.TOKEN,
			USERNAME: config?.USERNAME,
			PASSWORD: config?.PASSWORD,
			HEADERS: config?.HEADERS,
			ENCODE_PATH: config?.ENCODE_PATH,
			interceptors: {
				request: config?.interceptors?.request ?? new Interceptors(),
				response: config?.interceptors?.response ?? new Interceptors(),
      },
		});

		this.acceptedFacts = new AcceptedFactsService(this.request);
		this.batches = new BatchesService(this.request);
		this.coverage = new CoverageService(this.request);
		this.dataDictionary = new DataDictionaryService(this.request);
		this.default = new DefaultService(this.request);
		this.entities = new EntitiesService(this.request);
		this.enums = new EnumsService(this.request);
		this.errors = new ErrorsService(this.request);
		this.facts = new FactsService(this.request);
		this.feedback = new FeedbackService(this.request);
		this.holdings = new HoldingsService(this.request);
		this.isinMappings = new IsinMappingsService(this.request);
		this.keywords = new KeywordsService(this.request);
		this.kpiResults = new KpiResultsService(this.request);
		this.kpis = new KpisService(this.request);
		this.labels = new LabelsService(this.request);
		this.metrics = new MetricsService(this.request);
		this.portfolios = new PortfoliosService(this.request);
		this.questions = new QuestionsService(this.request);
		this.reportingStandards = new ReportingStandardsService(this.request);
		this.results = new ResultsService(this.request);
		this.search = new SearchService(this.request);
		this.segments = new SegmentsService(this.request);
		this.semanticSearch = new SemanticSearchService(this.request);
		this.snapshots = new SnapshotsService(this.request);
		this.sources = new SourcesService(this.request);
		this.stats = new StatsService(this.request);
		this.tasks = new TasksService(this.request);
		this.units = new UnitsService(this.request);
	}
}
