import { useRouter } from 'next/router'

export const useRedirectWithOriginalUrl = ({ landingPageUrl }: { landingPageUrl: string }) => {
  const router = useRouter()

  return {
    handleRedirect: () => {
      const hasOriginalRequestUrlForApp =
        window.history.length > 1 && document.referrer.indexOf(window.location.host) !== -1

      if (hasOriginalRequestUrlForApp) {
        router.push(document.referrer)
      } else {
        router.push(landingPageUrl)
      }
    },
  }
}
