import { getApps, initializeApp } from 'firebase/app'
import {
  AuthError,
  browserLocalPersistence,
  confirmPasswordReset,
  getAuth,
  GoogleAuthProvider,
  multiFactor,
  MultiFactorError,
  MultiFactorResolver,
  onAuthStateChanged,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  PhoneMultiFactorInfo,
  RecaptchaVerifier,
  SAMLAuthProvider,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  User,
} from 'firebase/auth'
import { apiConfig } from '../config'
import { RecaptchaFailedError } from './clientErrors'
import { NoAuthUserError } from './errors'
import { mapAuthProviderError } from './mapAuthProviderError'

export type AuthUser = User
export type AuthProviderError = AuthError | MultiFactorError
export type MultiFactorResolverWithPhoneMFAHint = Omit<MultiFactorResolver, 'hints'> & {
  hints: PhoneMultiFactorInfo[]
}

export const initialiseAuthProvider = () => {
  if (!getApps().length) {
    initializeApp(apiConfig.firebase)
  }
}

export const firebaseApp = initializeApp(apiConfig.firebase)
export const auth = getAuth(firebaseApp)

const getAuthProviderUser = (): AuthUser => {
  const user = auth.currentUser
  if (!user) {
    throw new NoAuthUserError()
  }
  return user
}

const checkAuthStatus = (): Promise<AuthUser | null> =>
  new Promise((resolve, _) => {
    onAuthStateChanged(auth, (user) => resolve(user))
  })

export const getToken = async (forceRefresh = false) => {
  const currentUser = await checkAuthStatus()
  if (!currentUser) {
    return ''
  }

  const token = await currentUser?.getIdToken(forceRefresh)
  return token
}

export const login = async (username: string, password: string) => {
  try {
    await setPersistence(auth, browserLocalPersistence)
    await signInWithEmailAndPassword(auth, username, password)
  } catch (err) {
    throw mapAuthProviderError(err as AuthProviderError)
  }
}

export enum SSOProviderId {
  OneLogin = 'saml.onelogin',
  Jupiter = 'saml.jupiter',
  Google = 'google.com',
}

export const SSOProviderMap = {
  [SSOProviderId.OneLogin]: 'One Login',
  [SSOProviderId.Jupiter]: 'Jupiter',
  [SSOProviderId.Google]: 'Google',
}

export const loginWithSSO = async (providerId: SSOProviderId) => {
  try {
    const samlAuthProvider = new SAMLAuthProvider(providerId)
    await signInWithPopup(auth, samlAuthProvider)
  } catch (err) {
    throw mapAuthProviderError(err as AuthProviderError)
  }
}

export const loginWithGoogle = async () => {
  try {
    const googleProvider = new GoogleAuthProvider()
    await signInWithPopup(auth, googleProvider)
  } catch (err) {
    throw mapAuthProviderError(err as AuthProviderError)
  }
}

export const logout = () => auth.signOut()

export const sendPasswordResetEmailApi = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email)
  } catch (err) {
    throw mapAuthProviderError(err as AuthProviderError)
  }
}

export const confirmPasswordResetApi = async (oobCode: string, newPassword: string) => {
  try {
    await confirmPasswordReset(auth, oobCode, newPassword)
  } catch (err) {
    throw mapAuthProviderError(err as AuthProviderError)
  }
}

export const unenrollMultifactor = async () => {
  const user = getAuthProviderUser()
  const { enrolledFactors } = multiFactor(user)
  if (!enrolledFactors.length) {
    return
  }
  return multiFactor(user)
    .unenroll(enrolledFactors[0] || '')
    .then(() => {
      // eslint-disable-next-line no-console
      console.log('Successfully unenrolled')
      window.location.pathname = 'login'
    })
}

export const triggerLoginMFA = (
  resolver: MultiFactorResolver,
  recaptchaVerifier?: RecaptchaVerifier,
) => {
  if (!recaptchaVerifier) {
    throw new RecaptchaFailedError()
  }

  // TODO: ask user which second factor to use
  const selectedFactorIndex = 0
  if (resolver.hints[selectedFactorIndex]?.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[selectedFactorIndex],
      session: resolver.session,
    }
    const phoneAuthProvider = new PhoneAuthProvider(auth)
    return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
  }

  throw new Error('Unsupported 2FA method')
}

export const confirmLoginMFA = (
  code: string,
  verificationId: string,
  resolver: MultiFactorResolver,
) => {
  const cred = PhoneAuthProvider.credential(verificationId, code)
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
  return resolver.resolveSignIn(multiFactorAssertion)
}

export const triggerMFAEnrollment = async (
  phoneNumber: string,
  recaptchaVerifier?: RecaptchaVerifier,
) => {
  if (!recaptchaVerifier) {
    throw new RecaptchaFailedError()
  }
  try {
    const user = getAuthProviderUser()
    const session = await multiFactor(user).getSession()
    const phoneAuthProvider = new PhoneAuthProvider(auth)
    return await phoneAuthProvider.verifyPhoneNumber({ phoneNumber, session }, recaptchaVerifier)
  } catch (err) {
    // TODO: Handle verifyPhoneNumber errors
    throw mapAuthProviderError(err as AuthProviderError)
  }
}

export const confirmMFAEnrollment = async (code: string, verificationId: string) => {
  try {
    const cred = PhoneAuthProvider.credential(verificationId, code)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
    const user = getAuthProviderUser()
    await multiFactor(user).enroll(multiFactorAssertion, 'net-purpose')
  } catch (err) {
    throw mapAuthProviderError(err as AuthProviderError)
  }
}
