import { onAuthStateChanged } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { auth, useUser } from '@netpurpose/api'
import { User } from '@netpurpose/types'

export const useLoggedIn = (): { user?: User | undefined } => {
  const [firebaseAuthUserId, setFirebaseAuthUserId] = useState<string | undefined>(
    auth.currentUser?.uid,
  )

  const {
    data: { user },
  } = useUser({
    enabled: Boolean(firebaseAuthUserId),
  })

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser?.uid !== firebaseAuthUserId) {
        setFirebaseAuthUserId(firebaseUser?.uid)
      }
    })
    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!firebaseAuthUserId) {
    return {}
  }

  return { user }
}
