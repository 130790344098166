import { FC } from 'react'

const MinusSquareIcon: FC = () => (
  <path
    d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM5 5V19H19V5H5ZM7 11H17V13H7V11Z"
    fill="currentColor"
  />
)

export default MinusSquareIcon
