import { FC } from 'react'

const CircleOutlineIcon: FC = () => (
  <>
    <circle cx="12" cy="12" r="12" fill="currentColor" />
    <circle cx="12" cy="12" r="6" fill="white" />
  </>
)

export default CircleOutlineIcon
