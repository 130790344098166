import styled from 'styled-components'

export const NavBarContainer = styled.div`
  color: ${({ theme }) => theme.colors.typeTertiary};
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.architecture3};
  margin-right: 0.5rem;

  width: 16rem;
  height: 1.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.75rem;
  cursor: pointer;
`

export const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const KeyboardPrompt = styled.div`
  background-color: ${({ theme }) => theme.colors.architecture3};
  border-radius: 0.125rem;
  padding: 0 0.25rem;

  font-size: 0.875rem;
  line-height: 1rem;
`

export const StyledCmdkRoot = styled.div<{ $hasResults: boolean }>`
  [cmdk-root] {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

    background: rgba(27, 27, 27, 0.5);
    backdrop-filter: blur(2px);
    z-index: 9999;

    > [cmdk-input] {
      border-radius: ${({ $hasResults }) => ($hasResults ? '0.75rem 0.75rem 0 0' : '0.75rem')};
    }
  }
`

export const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const DateText = styled.div`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
`
