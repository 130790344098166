import { FC } from 'react'
import { Button, NextLink as Link, withTooltip } from '@netpurpose/np-ui'
import { MultiplePortfolioActionProps } from '#components/portfolio/portfolioTypes'
import { config } from '#services/config'

type CompareButtonProps = {
  selectedPortfolioIds: MultiplePortfolioActionProps['selectedPortfolioIds']
  isDisabled: boolean
}

const ComparePortfoliosButton = withTooltip<CompareButtonProps>(
  ({ selectedPortfolioIds, isDisabled }) => (
    <Button level="secondary" disabled={isDisabled} size="small">
      <Link href={config.dynamicRoutes.comparePortfolios(selectedPortfolioIds)}>Compare</Link>
    </Button>
  ),
)

const ComparePortfoliosAction: FC<MultiplePortfolioActionProps> = ({ selectedPortfolioIds }) => {
  const isDisabled = selectedPortfolioIds.length !== 2
  return (
    <ComparePortfoliosButton
      isDisabled={isDisabled}
      showTooltip={isDisabled}
      tooltip="Select two items to compare"
      selectedPortfolioIds={selectedPortfolioIds}
    />
  )
}
export default ComparePortfoliosAction
