import { getMultiFactorResolver, MultiFactorError } from 'firebase/auth'
import { isString } from '@netpurpose/types'
import { logger } from '@netpurpose/utils'
import { auth, AuthProviderError } from './auth'
import {
  AuthError,
  InvalidEmailError,
  InvalidPasswordResetCodeError,
  MultifactorRequiredError,
  NetworkRequestError,
  RecaptchaDupeError,
  RequiresRecentLoginError,
  UnknownError,
  UserNotFoundError,
  WeakPasswordError,
} from './errors'

export const mapAuthProviderError = (err: AuthProviderError): AuthError => {
  // "email" is typed as a valid property of the error, but it's not clear
  // under which scenarios it's available to use. In any case we don't want to
  // capture the whole email as we should avoid PII making its way to Sentry,
  // but knowing the domain could be useful for debugging.
  const emailDomain = 'email' in err && isString(err.email) ? err?.email.split('@')?.[1] || '' : ''

  switch (err.code) {
    case 'auth/user-not-found':
    case 'auth/wrong-password':
    case 'auth/user-disabled':
      return new UserNotFoundError()
    case 'auth/invalid-email':
      return new InvalidEmailError()
    case 'auth/weak-password':
      return new WeakPasswordError()
    case 'auth/expired-action-code':
    case 'auth/invalid-action-code':
      return new InvalidPasswordResetCodeError()
    case 'auth/multi-factor-auth-required': {
      const mfaError = err as MultiFactorError
      const resolver = getMultiFactorResolver(auth, mfaError)
      return new MultifactorRequiredError(resolver)
    }
    case 'auth/requires-recent-login':
      return new RequiresRecentLoginError()
    case 'auth/captcha-check-failed':
      logger.warn({
        err: 'Auth capture check failed',
        context: { name: 'Extra Data', value: { 'Email Domain': emailDomain } },
      })
      return new RecaptchaDupeError()
    case 'auth/network-request-failed':
      logger.warn({
        err: 'Network request failed',
        context: { name: 'Extra Data', value: { 'Email Domain': emailDomain } },
      })
      return new NetworkRequestError()
    default:
      logger.error({
        err: 'Unknown auth error code',
        context: {
          name: 'Extra Data',
          value: { 'Error Code': err.code, 'Email Domain': emailDomain },
        },
      })
      return new UnknownError(err)
  }
}
