import { FC } from 'react'

const PlusSquareIcon: FC = () => (
  <path
    d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM11 11H7V13H11V17H13V13H17V11H13V7H11V11Z"
    fill="currentColor"
  />
)

export default PlusSquareIcon
