import { FC } from 'react'
import { PortfolioType } from '@netpurpose/api'
import { Tooltip } from '../Tooltip'
import { Badge, OverlappingBadges } from './PortfolioBadge.style'

export const badgeContent: Record<PortfolioType | 'company', { initials: string; title: string }> =
  {
    benchmark: { initials: 'B', title: 'Benchmark' },
    private: { initials: 'P', title: 'Portfolio' },
    watchlist: { initials: 'W', title: 'Watchlist' },
    'fund of funds': { initials: 'P', title: 'Portfolio' },
    fund: { initials: 'F', title: 'Fund' },
    company: { initials: 'C', title: 'Company' },
  }

export const PortfolioBadge: FC<{
  type: PortfolioType | 'company'
  withoutTooltip?: boolean
  style?: React.CSSProperties
}> = ({ type, withoutTooltip = false, style }) => {
  const initials = badgeContent[type]?.initials
  const title = badgeContent[type]?.title

  const props = {
    'aria-label': title,
    type,
    className: 'follow-table-row-highlight-inverted',
    style,
  }

  if (!initials || !title) {
    return null
  }
  if (withoutTooltip) {
    return <Badge {...props}>{initials}</Badge>
  }
  return (
    <Tooltip placement="right" title={title}>
      <Badge {...props}>{initials}</Badge>
    </Tooltip>
  )
}

export const PortfolioBadgesGroup = ({ types }: { types: PortfolioType[] }) => {
  // NOTE: this is to limit the number of badges shown when collapsed
  // any more than 10 is not necessary
  const maxTypes = types.slice(0, 10)
  return (
    <OverlappingBadges>
      {maxTypes.map((type, idx) => {
        // NOTE: should not be higher than modals' zIndex
        const startingZIndex = 10
        return (
          <PortfolioBadge
            key={`${type}-${idx}`}
            type={type}
            style={idx === 0 ? { zIndex: startingZIndex } : { zIndex: startingZIndex - idx }}
          />
        )
      })}
    </OverlappingBadges>
  )
}
