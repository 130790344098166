import { FC } from 'react'
import { Goal } from '@netpurpose/api'
import { Icon, IconProps } from '../Icon'
import { Tooltip } from '../Tooltip'
import { SpotContainer } from './ComparisonSpot.style'

type Props = {
  tooltipText: string
  goal: Goal | undefined
  isIncrease: boolean
}

const iconConfig = {
  better: {
    icon: 'CircleFilled',
    color: 'semanticPositive',
    alt: 'Better icon',
  },
  worse: {
    icon: 'CircleOutline',
    color: 'semanticNegative',
    alt: 'Worse icon',
  },
  neutral: {
    icon: 'CircleDash',
    color: 'typeTertiary',
    alt: 'Neutral icon',
  },
} as const

const getIconProps = (isIncrease: boolean, goal: Goal): IconProps => {
  if (goal === Goal.INCREASE) {
    return isIncrease ? iconConfig.better : iconConfig.worse
  }

  if (goal === Goal.DECREASE) {
    return isIncrease ? iconConfig.worse : iconConfig.better
  }

  return iconConfig.neutral
}

const ComparisonSpot: FC<Props> = ({ tooltipText, goal, isIncrease }) => {
  if (!goal) {
    return null
  }
  const iconProps = getIconProps(isIncrease, goal)

  return (
    <Tooltip placement="right" title={tooltipText}>
      <SpotContainer>
        <Icon size="xs" hideTooltip {...iconProps} />
      </SpotContainer>
    </Tooltip>
  )
}

export default ComparisonSpot
