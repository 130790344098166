import { MultiFactorResolver } from 'firebase/auth'
import { AuthProviderError } from './auth'

export class InvalidEmailError extends Error {
  constructor() {
    super('Improperly formatted e-mail')
  }
}

export class InvalidPasswordResetCodeError extends Error {
  constructor() {
    super('Your password reset link has expired')
  }
}

export class MultifactorRequiredError extends Error {
  resolver: MultiFactorResolver

  constructor(resolver: MultiFactorResolver) {
    super('Second factor required')
    this.resolver = resolver
  }
}

export class NetworkRequestError extends Error {
  constructor() {
    super(
      'There appears to be an issue with your network. Please check your Internet connection, VPN or firewall settings and try again.',
    )
  }
}

export class NoAuthUserError extends Error {
  constructor() {
    super('No current user found in auth provider')
  }
}

export class RecaptchaDupeError extends Error {
  constructor() {
    super('Recaptcha verification failed - DUPE')
  }
}

export class RequiresRecentLoginError extends Error {
  constructor() {
    super(
      'This operation is sensitive and requires recent authentication. Log in again before retrying this request.',
    )
  }
}

export class UnknownError extends Error {
  originalError?: AuthProviderError

  constructor(originalError?: AuthProviderError) {
    super('Unknown error')
    // @ts-expect-error
    this.originalError = originalError
  }
}

export class UserNotFoundError extends Error {
  constructor() {
    super('We cannot find an account with that username and password')
  }
}

export class WeakPasswordError extends Error {
  constructor() {
    super('Your password must be at least 8 characters')
  }
}

export type AuthError =
  | UserNotFoundError
  | InvalidEmailError
  | UnknownError
  | MultifactorRequiredError
  | WeakPasswordError
  | NoAuthUserError
  | InvalidPasswordResetCodeError
  | RequiresRecentLoginError
  | RecaptchaDupeError
  | NetworkRequestError
