import { FC } from 'react'

const CircleDashIcon: FC = () => (
  <>
    <circle cx="12" cy="12" r="12" fill="currentColor" />
    <rect x="6" y="9" width="12" height="6" fill="white" />
  </>
)

export default CircleDashIcon
