import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { Interceptors } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { ActivitiesService } from './sdk.gen';
import { ActivityCostsService } from './sdk.gen';
import { DatapointsService } from './sdk.gen';
import { DefaultService } from './sdk.gen';
import { EstimationsService } from './sdk.gen';
import { TaxonomiesService } from './sdk.gen';
import { WorkbooksService } from './sdk.gen';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {

	public readonly activities: ActivitiesService;
	public readonly activityCosts: ActivityCostsService;
	public readonly datapoints: DatapointsService;
	public readonly default: DefaultService;
	public readonly estimations: EstimationsService;
	public readonly taxonomies: TaxonomiesService;
	public readonly workbooks: WorkbooksService;

	public readonly request: BaseHttpRequest;

	constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
		this.request = new HttpRequest({
			BASE: config?.BASE ?? '',
			VERSION: config?.VERSION ?? '0.1.0',
			WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
			CREDENTIALS: config?.CREDENTIALS ?? 'include',
			TOKEN: config?.TOKEN,
			USERNAME: config?.USERNAME,
			PASSWORD: config?.PASSWORD,
			HEADERS: config?.HEADERS,
			ENCODE_PATH: config?.ENCODE_PATH,
			interceptors: {
				request: config?.interceptors?.request ?? new Interceptors(),
				response: config?.interceptors?.response ?? new Interceptors(),
      },
		});

		this.activities = new ActivitiesService(this.request);
		this.activityCosts = new ActivityCostsService(this.request);
		this.datapoints = new DatapointsService(this.request);
		this.default = new DefaultService(this.request);
		this.estimations = new EstimationsService(this.request);
		this.taxonomies = new TaxonomiesService(this.request);
		this.workbooks = new WorkbooksService(this.request);
	}
}
