import { DatapointType } from '@netpurpose/types'
import { DatapointTypes } from '../../generated/modelling'
import { BackendDatapoint } from './types'

export const datapointTypeMap: Record<DatapointType, DatapointTypes> = {
  [DatapointType.Datapoint]: DatapointTypes.DATAPOINT,
  [DatapointType.Revenue]: DatapointTypes.REVENUE,
  [DatapointType.Output]: DatapointTypes.OUTPUT,
  [DatapointType.MacroFactor]: DatapointTypes.MACRO_FACTOR,
  [DatapointType.OutcomeLevel1]: DatapointTypes.OUTCOME_LEVEL_1,
  [DatapointType.OutcomeLevel2]: DatapointTypes.OUTCOME_LEVEL_2,
  [DatapointType.TechnologyCost]: DatapointTypes.TECHNOLOGY_COST,
  [DatapointType.Impact]: DatapointTypes.IMPACT,
}

const reverseDatapointTypeMap = (
  Object.entries(datapointTypeMap) as [DatapointType, string][]
).reduce<Record<string, DatapointType>>(
  (acc, [k, v]) => ({
    ...acc,
    [v]: k,
  }),
  {},
)

export const parseDatapointType = (type: BackendDatapoint['type']) =>
  reverseDatapointTypeMap[type] || DatapointType.Datapoint
