import Account from './Account'
import Add from './Add'
import Analysed from './Analysed'
import ApproxEquals from './ApproxEquals'
import ArrowBack from './ArrowBack'
import ArrowCircle from './ArrowCircle'
import ArrowDown from './ArrowDown'
import ArrowLeft from './ArrowLeft'
import ArrowRight from './ArrowRight'
import ArrowUp from './ArrowUp'
import Back from './Back'
import Book from './Book'
import BreadcrumbSeparator from './BreadcrumbSeparator'
import Check from './Check'
import ChevronDown from './ChevronDown'
import ChevronLeft from './ChevronLeft'
import ChevronRight from './ChevronRight'
import CircleDash from './CircleDash'
import CircleFilled from './CircleFilled'
import CircleOutline from './CircleOutline'
import Close from './Close'
import Configure from './Configure'
import Copy from './Copy'
import Cross from './Cross'
import Dash from './Dash'
import DataPage from './DataPage'
import Delete from './Delete'
import Documents from './Documents'
import DoubleChevronLeft from './DoubleChevronLeft'
import DoubleChevronRight from './DoubleChevronRight'
import Download from './Download'
import Downloading from './Downloading'
import Drag from './Drag'
import DropdownArrow from './DropdownArrow'
import Earth from './Earth'
import Email from './Email'
import Excel from './Excel'
import ExclamationInCircle from './ExclamationInCircle'
import Eye from './Eye'
import EyeClosed from './EyeClosed'
import EyeOpen from './EyeOpen'
import Filter from './Filter'
import FunctionFormula from './FunctionFormula'
import Fundamentals from './Fundamentals'
import Help from './Help'
import Hidden from './Hidden'
import Info from './Info'
import Kebab from './Kebab'
import Key from './Key'
import LightningBolt from './LightningBolt'
import Link from './Link'
import ListView from './ListView'
import Lock from './Lock'
import Logo from './Logo'
import LogoText from './LogoText'
import LogOut from './LogOut'
import MagnifyingGlass from './MagnifyingGlass'
import Menu from './Menu'
import Minus from './Minus'
import MinusSquare from './MinusSquare'
import ModeBright from './ModeBright'
import ModeDark from './ModeDark'
import No from './No'
import { Pencil } from './Pencil'
import { Pending } from './Pending'
import { PendingReverse } from './PendingReverse'
import Plus from './Plus'
import PlusSquare from './PlusSquare'
import Processing from './Processing'
import RobotHead from './RobotHead'
import SdgAffordableHousing from './SdgAffordableHousing'
import SdgAir from './SdgAir'
import SdgCleanEnergy from './SdgCleanEnergy'
import SdgClimateChange from './SdgClimateChange'
import SdgDecentWork from './SdgDecentWork'
import SdgEducation from './SdgEducation'
import SdgFinancialInclusion from './SdgFinancialInclusion'
import SdgFood from './SdgFood'
import SdgGenderDiversity from './SdgGenderDiversity'
import SdgHealthcare from './SdgHealthcare'
import SdgIndustry from './SdgIndustry'
import SdgSustainableFinance from './SdgSustainableFinance'
import SdgWaste from './SdgWaste'
import SdgWater from './SdgWater'
import Search from './Search'
import Settings from './Settings'
import Stars from './Stars'
import Stats from './Stats'
import Success from './Success'
import Swap from './Swap'
import ThumbnailView from './ThumbnailView'
import Tick from './Tick'
import TickInCircle from './TickInCircle'
import Upload from './Upload'
import UserProfile from './UserProfile'
import Warning from './Warning'
import XLarge from './XLarge'
import XSmall from './XSmall'

export const icons = {
  Account,
  Add,
  Analysed,
  ApproxEquals,
  ArrowBack,
  ArrowCircle,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Back,
  Book,
  BreadcrumbSeparator,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  CircleDash,
  CircleFilled,
  CircleOutline,
  Close,
  Configure,
  Copy,
  Cross,
  ExclamationInCircle,
  Dash,
  DataPage,
  Documents,
  DoubleChevronLeft,
  DoubleChevronRight,
  Delete,
  Download,
  Downloading,
  Drag,
  DropdownArrow,
  Earth,
  Email,
  Excel,
  Eye,
  EyeClosed,
  EyeOpen,
  Filter,
  FunctionFormula,
  Fundamentals,
  Help,
  Hidden,
  Info,
  Key,
  Kebab,
  LightningBolt,
  Link,
  ListView,
  Lock,
  Logo,
  LogoText,
  LogOut,
  MagnifyingGlass,
  Menu,
  Minus,
  MinusSquare,
  ModeBright,
  ModeDark,
  No,
  Pencil,
  Pending,
  PendingReverse,
  Plus,
  PlusSquare,
  Processing,
  RobotHead,
  SdgAffordableHousing,
  SdgAir,
  SdgCleanEnergy,
  SdgClimateChange,
  SdgDecentWork,
  SdgEducation,
  SdgFinancialInclusion,
  SdgFood,
  SdgGenderDiversity,
  SdgHealthcare,
  SdgIndustry,
  SdgSustainableFinance,
  SdgWaste,
  SdgWater,
  Search,
  Settings,
  Stars,
  Stats,
  Success,
  Swap,
  ThumbnailView,
  Tick,
  TickInCircle,
  Upload,
  UserProfile,
  Warning,
  XLarge,
  XSmall,
}

export type IconName = keyof typeof icons
