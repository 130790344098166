import { Portfolio, PortfolioType } from '@netpurpose/api'
import { ResultStatus } from '@netpurpose/types'
import { formatDateForTable } from '@netpurpose/utils'
import { PortfolioCreationTask } from '#context/tasks'

export type PortfolioForDisplay = Pick<Portfolio, 'id' | 'name' | 'value' | 'valueCovered'> & {
  resultStatus: ResultStatus
  type: PortfolioType
  valuationDate: string
  numHoldings?: number
  numHoldingsCovered?: number
  children?: PortfolioForDisplay[] | undefined
  rowId: number
  parentPortfolioId?: number
}

export const formatPendingPortfolioRow = (
  task: PortfolioCreationTask,
  index: number,
): PortfolioForDisplay => ({
  id: index,
  rowId: index,
  resultStatus: task.status,
  name: task.initial.name,
  type: task.initial.type,
  value: task.initial.totalValue,
  valuationDate: formatDateForTable(task.initial.valuationDate),
})

export const formatPortfolioRow = (portfolio: Portfolio): PortfolioForDisplay => ({
  id: portfolio.id,
  rowId: portfolio.id,
  resultStatus: 'complete',
  name: portfolio.name,
  type: portfolio.type,
  ...(portfolio.value ? { value: portfolio.value } : {}),
  ...(portfolio.valueCovered ? { valueCovered: portfolio.valueCovered } : {}),
  valuationDate: formatDateForTable(portfolio.valuationDate),
  numHoldings: portfolio.numHoldings,
  numHoldingsCovered: portfolio.numHoldingsCovered,
  children: portfolio.funds
    ? portfolio.funds.map((f) => ({
        ...formatPortfolioRow(f),
        // NOTE: this is to make the row unique when the same fund exists in multiple portfolios
        rowId: f.id + portfolio.id + 1000000,
        parentPortfolioId: portfolio.id,
      }))
    : undefined,
})
